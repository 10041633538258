jQuery(document).ready(function ($) {

	// ========================================================
	// INCLUDE PARTIAL FILES VIA GULP-INCLUDE
	// ========================================================

	const SourceTracker = settings => {
		const module = {
			settings,
			init: function() {
				if (typeof Cookies === 'undefined') {
					console.info('Please install js-cookie library to enable the SourceTracker library.');
					return;
				}
				this.setSourceCookies();
			},
			setSourceCookies: function() {
				const params = new URLSearchParams(window.location.search);
				const cookie = {};
	
				// Save all url params in cookie object
				params.forEach((val, key) => cookie[key] = val);
	
				// Add any additional values to cookie object
				cookie['referrer'] = document.referrer;
				cookie['landing_pg'] = window.location.href;
	
				// Save first-touch cookie (only if not previously set)
				if (typeof Cookies.get(this.settings.cookie.name.ft) === 'undefined') {
					Cookies.set(this.settings.cookie.name.ft, JSON.stringify(cookie), this.settings.cookie.opts);
				}
	
				// In all instances save last-touch cookie
				Cookies.set(this.settings.cookie.name.lt, JSON.stringify(cookie), this.settings.cookie.opts);
			},
			populateFormFields: function() {
				const cookieName = this.settings.cookie.name[this.settings.strategy];
				const vars = Cookies.get(cookieName) ? JSON.parse(Cookies.get(cookieName)) : {};
				const _self = this;
	
				Object.keys(vars).forEach(key => {
					$(`[${_self.settings.forms.prefillAttr}="${key}"]`).each((i, el) => {
						$(el).val(vars[key]);
					});
				});
			}
		}
	
		module.init();
	
		return module;
	}
	
	/**
	 * Jquery document ready.
	 * Initialize SourceTracker library.
	 */
	$(() => {
		// Init source tracking library
		const st = new SourceTracker({
			strategy: 'ft', // "ft" or "lt" (first-touch or last-touch)
			cookie: {
				name: {
					ft: 'trmk_src_first', // cookie name : first touch attribution
					lt: 'trmk_src_last', // cookie name : last touch attribution
				},
				opts: {
					expires: 1, // 1 day
					path: '/' // full site
				}
			},
			forms: {
				prefillAttr: 'data-prefill', // the data attribute of form field to prefill
			},
		});
	
		// Gravity forms post render event
		// - Makes sure fields are always popuplated with correct cookie values even if re-rendered (i.e. validation)
		$(document).on('gform_post_render', function (event, form_id, current_page) {
			st.populateFormFields();
		});
	
		// Fallback for if gform_post_render doesn't fire
		// - gform_post_render is supposed to fire on page load, but seems to not in all cases
		st.populateFormFields();
	})
	


	// ========================================================
	// CALL TRACKING
	// ========================================================

    // Track the Click to Call Event inside Analytics
    if(('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch){
        $('[href*="tel:"]').on('click',function(){
            console.log('Mobile Clicked');
            if (typeof gtag_report_conversion === 'function') {
                gtag_report_conversion($(this).prop('href'));
            }

            // Get the event label with fallback if none is set
            if (this.hasAttribute('data-event-label')) {
                var event_label = $(this).data('event-label');
            } else {
                var event_label = 'Body';
            }

            if (typeof gtag === 'function') {
                gtag('event', 'click', {
                    'event_category': 'Click-To-Call: LP',
                    'event_action': 'Mobile Call',
                    'event_label': event_label,
                });
            }
        });
    } else {
        $('[href*="tel:"]').on('click',function(){
            console.log('Desktop Clicked');
            return false;
        });
    }


    // ===== GLOBAL HELPERS
    // ==========================================

    var global_helpers = {
        settings: {
            body: $('body'),
            header: $('header'),
            footer: $('footer'),
            hash: window.location.hash,
            back_to_top: $('.back-to-top'),
            data_anchor_links: $('[data-anchor-link]'),
        },

        init: function () {
            // Scroll to a hash if there is one
            if (this.settings.hash.length) {
                setTimeout(function () {
                    global_helpers.scrollToHash();
                }, 500);
            }
            // Data Anchor Links
            if (this.settings.data_anchor_links.length) {
                global_helpers.dataAnchorLinks();
            }

            this.bindUIActions();
        },

        bindUIActions: function () {
            // Back to top buttons
            global_helpers.settings.back_to_top.on('click', function () {
                $('html, body').animate({
                    scrollTop: 0,
                });
            });

            // Track various events for Analytics
            $('.js-track-event').on('click', function () {
                global_helpers.trackAnalyticsEvents($(this));
                if ($(this).hasClass('input-change')) {
                    $(this).removeClass('js-track-event');
                }
            });
        },

        // Allow smooth scrolling to data-anchor-link items from WYSIWYG content links
        dataAnchorLinks: function () {
            // Loop over everything found, and find the matching link
            global_helpers.settings.data_anchor_links.each(function () {
                $(this).on('click', function () {
                    var link = $(this).attr('data-anchor-link');

                    // Get the target by ID
                    var target = $('#' + link);

                    var header = global_helpers.settings.header.outerHeight();
                    var offset = target.offset().top - header;

                    $('html, body').animate({
                        scrollTop: offset - 45,
                    });

                    return false;
                });
            });
        },

        // Returns a function that, as long as it continues to be invoked, will not
        // be triggered. The function will be called after it stops being called for
        // N milliseconds. If 'immediate' is passed, trigger the function on the
        // leading edge, instead of the trailing.
        //
        // Use:  $(window).resize(global_helpers.debounce(function() { ...[your code here]... }, 500));
        // Source:  http://davidwalsh.name/javascript-debounce-function
        debounce: function (func, wait, immediate) {
            var timeout;
            return function () {
                var context = this,
                    args = arguments;
                var later = function () {
                    timeout = null;
                    if (!immediate) {
                        func.apply(context, args);
                    }
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) {
                    func.apply(context, args);
                }
            };
        },

        // Smooth scroll to the hash content
        scrollToHash: function () {
            // Get the hashed target
            var content = $(global_helpers.settings.hash);

            if (content.length) {
                // Get the current header height if on mobile
                var header = global_helpers.settings.header.outerHeight();
                // Get the offset and add the header height
                var offset = content.offset().top - header - 35;
                // Scroll to the desired content with offset
                $('html, body').animate({
                    scrollTop: offset,
                });
            }
        },

        // Analytics Event Tracking
        trackAnalyticsEvents: function ($elem) {
            if (typeof gtag === 'function') {
                gtag('event', 'click', {
                    'event_category': $elem.data('event-category'),
                    'event_action': $elem.data('event-action'),
                    'event_label': $elem.data('event-label'),
                });
            }
        },

    };
    global_helpers.init();


    // HEADER
    // ===================================================

    var header = {

        settings: {
            header: $('.header'),
            location_dropdown: $('header .has-dropdown .trigger'),
        },

        init: function () {
            header.bindUIActions();
        },

        bindUIActions: function () {
            // On clicking the location dropdown
            this.settings.location_dropdown.on('click', header.toggleLocationDropdown);

            // Show/Hide menus when scrolling up or down
            $(window).on('scroll', function () {
                if ($(window).scrollTop() >= header.settings.header.height() * 3 ) {
                    header.settings.header.addClass('sticky');
                } else {
                    header.settings.header.removeClass('sticky');
                }
            });
        },

        // Location Dropdown
        toggleLocationDropdown: function() {
            if (!header.settings.header.hasClass('locations-open')) {
                header.settings.header.addClass('locations-open');
            } else {
                header.settings.header.removeClass('locations-open');
            }
        },

    };
    header.init();


    // CONTACT FORM CONTROLS
    // ===================================================

    var form_controls = {
        settings: {
            required: $('form'),
            uniform_elements: 'select, input[type="checkbox"], input[type="radio"]',
            form_inputs: 'form input, form select, form textarea',
        },

        init: function () {
            if (this.settings.required.length) {
                form_controls.bindUIActions();
                form_controls.uniformInit();
                form_controls.initializeLabels();
            }
        },

        bindUIActions: function () {
            // Activate rows on click
            $(document).on('blur focus', this.settings.form_inputs, form_controls.toggleLabels);
        },

        // Applies uniform styling to form elements (i.e. <select>, etc.)
        uniformInit: function () {
            $(this.settings.uniform_elements).uniform({
                selectAutoWidth: false,
            });
        },

        // On page load activate labels that have a value in them
        initializeLabels: function () {
            $(this.settings.form_inputs).each(function () {
				if ($(this).val() !== null && $(this).val().length) {
                    $(this).closest('.form-row, .ginput_container:not(.ginput_complex), .name_first, .name_last').addClass('active');
                }
            });
        },

        // Toggle label positioning
        toggleLabels: function (e) {
            // Check for empty inputs and deactivate labels on blur
            if (e.type == 'focusout') {
                // If it's empty and not a date field
				if ($(this).val() !== null && !$(this).val().length) {
                    $(this).closest('.form-row, .ginput_container:not(.ginput_complex), .name_first, .name_last').removeClass('active');
                }
            } else {
                $(this).closest('.form-row, .ginput_container:not(.ginput_complex), .name_first, .name_last').addClass('active');

                // If it's a textarea
                if ($(this).is('textarea')) {
                    $(this).addClass('open');
                }
            }
        },
    };
    form_controls.init();


    // ACF BLOCKS
    // ===================================================

    var acf_blocks = {

        settings: {
			accordions: $('.block-accordion'),
            energy_calculator: $('.block-lp-energy-calculator'),
            image_gallery: $('.block-image-gallery'),
            image_gallery_slider: $('.block-lp-image-gallery .slider'),
            product_slider: $('.block-image-carousel'),
            testimonial_slider: $('.online-reviews'),
            video_gallery: $('.block-video-gallery'),
        },

        init: function () {
			// Accordions
            if (this.settings.accordions.length) {
                this.accordions();
            }
            // Energy Savings Calculator
            if (this.settings.energy_calculator.length) {
                this.rangeSlider();
            }
            // Image Gallery
            if (this.settings.image_gallery.length) {
                this.imageGallery();
            }
            // Image Gallery - Tabbed Slider
            if (this.settings.image_gallery_slider.length) {
                this.imageGallerySlider();
            }
            // Product Slider
            if (this.settings.product_slider.length) {
                this.productSlider();
            }
            // Testimonials Slider
            if (this.settings.testimonial_slider.length) {
                this.testimonialSlider();
            }
            // Video Gallery
            if (this.settings.video_gallery.length) {
                this.videoGallery();
            }
        },

		// Accordions
        accordions: function () {
            this.settings.accordions.find('.title').on('click', function () {
                $(this).closest('.block-accordion').toggleClass('open');
            });
        },

        // Image Gallery
        imageGallery: function () {
            // If there is a view more button
            var view_more = $('.block-lp-image-gallery .view-more');

            if (view_more.length) {
                view_more.on('click', function () {
                    // Remove hidden class from all blocks in that gallery
                    $(this)
                        .closest('.block-lp-image-gallery')
                        .find('.block')
                        .removeClass('hidden hidden-mobile');
                    // If this is a slider then reset the container height
                    var height = $(this)
                        .closest('.block-lp-image-gallery')
                        .find('.slick-active')
                        .outerHeight();
                    $(this)
                        .closest('.block-lp-image-gallery')
                        .find('.image-grid .slick-list')
                        .css('height', height + 'px');
                    // Then remove the button
                    $(this).remove();
                });
            }
        },

        // Image Gallery - Tabbed Slider
        imageGallerySlider: function () {
            acf_blocks.settings.image_gallery_slider.each(function () {
                // Product Slider
                $(this).slick({
                    adaptiveHeight: true,
                    arrows: false,
                    draggable: false,
                    fade: true,
                    infinite: false,
                    slidesToShow: 1,
                    speed: 400,
                    swipe: false,
                    touchMove: false,
                });

                // Tab Controls
                var tabs = $(this)
                    .closest('.block-lp-image-gallery')
                    .find('.slider-tabs .tab');

                // Bind event listeners
                tabs.on('click', function () {
                    // Activate Tab
                    tabs.removeClass('active');
                    $(this).addClass('active');

                    var index = $(this).index();

                    // Advance Slider
                    $(this)
                        .closest('.block-lp-image-gallery')
                        .find('.slider')
                        .slick('slickGoTo', index);
                });

            });
        },

        // Product Slider
        productSlider: function () {
            acf_blocks.settings.product_slider.each(function () {
                $(this)
                    .find('.image-slider')
                    .slick({
                        appendArrows: $(this).find('.controls'),
                        appendDots: $(this).find('.controls'),
                        asNavFor: $(this).find('.text-slider'),
                        centerMode: true,
                        centerPadding: '45px',
                        dots: true,
                        dotsClass: 'slick-dots-circles',
                        mobileFirst: true,
                        nextArrow:
                            '<div class="next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.09 32"><polygon points="0 27.91 4.09 32 20.09 16 16 11.91 4.09 0 0 4.09 11.91 16 0 27.91"/></svg></div>',
                        prevArrow:
                            '<div class="prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.09 32"><polygon points="0 27.91 4.09 32 20.09 16 16 11.91 4.09 0 0 4.09 11.91 16 0 27.91"/></svg></div>',
                        slidesToShow: 1,
                        speed: 400,
                        touchThreshold: 25,
                        responsive: [
                            {
                                breakpoint: 499,
                                settings: {
                                    centerPadding: '110px',
                                },
                            },
                        ],
                    });

                $(this).find('.text-slider').slick({
                    arrows: false,
                    draggable: false,
                    fade: true,
                    swipe: false,
                    touchMove: false,
                });

                // If a product topic has been set, ie ?t=b, then switch to that slide on load
                if (typeof product_topic !== 'undefined' && product_topic != 'Replacement') {
                    var slide = $('.image-slider .slick-slide').not('.slick-cloned').find('[data-product-slide="'+product_topic+'"]').first().closest('.slick-slide').data('slick-index');
                    $(this).find('.image-slider').slick('slickGoTo', slide);
                }

            });
        },

        rangeSlider: function () {
            acf_blocks.settings.energy_calculator.each(function () {
                // Range Slider
                if ($(this).find('.range-slider').length) {
                    var range = $(this).find('.range-slider');

                    // Set initial index
                    switchValues(0);

                    // On slider change
                    range.find('input').on('change input', function () {
                        switchValues($(this).val());
                        followSliderButton($(this).val());
                    });

                    // Activate values for both areas
                    function switchValues(index) {
                        // Remove all values first
                        range.find('.number').removeClass('active');
                        // Slider Display
                        range
                            .find('.slider-display .number')
                            .eq(index)
                            .addClass('active');
                        // Range Display
                        range
                            .find('.range-display .number')
                            .eq(index)
                            .addClass('active');
                    }

                    // Move the text to follow the range slider button
                    function followSliderButton(index) {
                        // Move things a little differently on mobile/larger screens to avoid overlap
                        if ($(window).width() < 640) {
                            var offset = 6;
                        } else {
                            var offset = 1.75;
                        }

                        // Get the number of values and compute %
                        var percentage = 100 / range.find('.range-input').attr('max') - offset;
                        var position = index * percentage;

                        // Move the text
                        range.find('.slider-display').css('left', position + '%');
                    }
                }
            });
        },

        testimonialSlider: function () {
            // Initialize the Online Reviews Slider
            acf_blocks.settings.testimonial_slider.each(function () {
                $(this)
                    .find('.slider')
                    .slick({
                        appendArrows: $(this).find('.controls'),
                        appendDots: $(this).find('.controls'),
                        cssEase: 'ease-in-out',
                        dots: true,
                        dotsClass: 'slick-dots-circles',
                        nextArrow:
                            '<div class="next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.09 32"><polygon points="0 27.91 4.09 32 20.09 16 16 11.91 4.09 0 0 4.09 11.91 16 0 27.91"/></svg></div>',
                        prevArrow:
                            '<div class="prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.09 32"><polygon points="0 27.91 4.09 32 20.09 16 16 11.91 4.09 0 0 4.09 11.91 16 0 27.91"/></svg></div>',
                        slidesToShow: 1,
                        speed: 500,
                        touchThreshold: 25,
                    });
            });
        },

        // Video Gallery
        videoGallery: function () {
            // If there is a view more button
            var view_more = $('.block-video-gallery .view-more');

            if (view_more.length) {
                view_more.on('click', function () {
                    // Remove hidden class from all blocks in that gallery
                    $(this)
                        .closest('.block-video-gallery')
                        .find('.hidden')
                        .removeClass('hidden');
                    // Then remove the button
                    $(this).remove();
                });
            }
        },
    };
    acf_blocks.init();


    // ===== MISCELLANEOUS ITEMS
    // ==========================================

    // var misc = {

    //     settings: {
    //         exit_popup: $('.exit-popup-overlay'),
    //     },

    //     init: function () {
    //         // Exit Popup
    //         if (this.settings.exit_popup.length) {
    //             this.exitPopup();
    //         }
    //     },

    //     // Exit Popup
    //     exitPopup: function() {

    //         // Check the sessionStorage
    //         if (sessionStorage.getItem('exit_popup') != 'closed') {

    //             // Activate exit popup when mouse leaves the window
    //             $('html').on('mouseleave', function(e) {
    //                 if (e.clientY <= 0) {
    //                     $('body').addClass('no-scroll');
    //                     $('.exit-popup-overlay').addClass('active');
    //                     // Turn off event listener
    //                     $(this).off('mouseleave');
    //                 }
    //             });

    //             // Close the popup on clicking a few items
    //             $('.exit-popup .close, .exit-popup a').on('click', function() {
    //                 $('body').removeClass('no-scroll');
    //                 $('.exit-popup-overlay').remove();
    //                 // Set a variable to prevent from opening again this session
    //                 sessionStorage.setItem('exit_popup', 'closed');
    //             });

    //         }
    //     },

    // }
    // misc.init();

	/**
	 * Video play toggle button
	 */
	$('.btn-toggle-playback').on('click', function() {
		var $this = $(this);
		var $text = $this.find('.js-text');
		var $video = $('#' + $this.data('video'));

		if ($this.data('playing') === true) {
			$this.data('playing', false);
			$this.addClass('paused')
			$text.text('Play');
			$video[0].pause();
		} else {
			$this.data('playing', true);
			$this.removeClass('paused')
			$text.text('Pause');
			$video[0].play();
		}
	});

	/**
	 * Pause video when browser tab/window is not active.
	 */
	var $heroVideo = $('.block-lp-hero video');
	var heroVideo = $heroVideo.length ? $heroVideo[0] : null;

	// Handle page visibility change:
	// - If the page is hidden, pause the video
	// - If the page is shown, play the video
	document.addEventListener("visibilitychange", () => {
		if (heroVideo) {
			if (document.hidden) {
				heroVideo.pause();
			} else {
				heroVideo.play();
			}
		}
	});

	/**
	 * Read more button toggles.
	 */
	$('[data-js-read-more]').on('click', function(e) {
		var $button = $(this);
		var target = $button.data('js-read-more');

		e.preventDefault();

		// Toggle hidden message content
		$(target).attr('aria-expanded', $(target).is(':visible'))
			.slideToggle();

		// Toggle button text
		$button.find('span').each(function () {
			var $button = $(this);
			$button.toggle(!$button.is(':visible'));
		});
	});


	// ========================================================
	// GRAVITY FORMS FLOATING LABELS
	// - Using Bootstrap 5's floating labels
	// - @link https://getbootstrap.com/docs/5.3/forms/floating-labels/
	// ========================================================

	const fields = [
		'.gfield--type-text label',
		'.gfield--type-phone label',
		'.gfield--type-email label',
		'.gfield--type-name label',
		'.gfield--type-select label',
		'.gfield--type-textarea label',
		'.gfield--type-address label'
	];

	function initFloatingLabels(formId) {
		// All field labels
		$(`#gform_${formId}`).find(fields.join(',')).each((i, el) => {
			const $label = $(el);
			const $field = $label.parents('.gfield');
			let $container = $label.next('.ginput_container');

			// Complex fields (i.e. name or address fields)
			if ($field.hasClass('gfield--type-name') || $field.hasClass('gfield--type-address')) {
				$container = $label.parent();
			}

			// Return if already initialized (i.e. label already moved)
			if ($container.length === 0) {
				return;
			}

			// Add Bootstrap class (except select boxes)
			// if (!$field.hasClass('gfield--input-type-select') && !$container.hasClass('ginput_address_country')) {
			// 	$container.addClass('form-floating');
			// }

			// Move label into correct position
			$label.remove().appendTo($container);
		});
		// Special handling for select forms only on change event
		// $('.gfield--type-select select, .ginput_address_country select').on('change', e => {
		// 	$(e.currentTarget).parent().addClass('form-floating');
		// });
	}

	// Init floating labels on page load
	$('.gform_wrapper form').each((i, el) => initFloatingLabels($(el).data('formid')));

	// Gravity forms post render hook to reinit after validation or paging
	$(document).on('gform_post_render', function (event, form_id, current_page) {
		initFloatingLabels(form_id);
		form_controls.init();
	});

});


//Team block script
document.querySelectorAll('.js-popup-team').forEach(function(button) {
    button.addEventListener('click', function(e) {
        e.preventDefault();
        const memberId = '#member-' + this.getAttribute('data-member');
        const memberElement = document.querySelector(memberId);
        if (memberElement) {
            memberElement.style.display = 'flex';
            document.body.style.overflow = 'hidden';
        }
    });
});

document.querySelectorAll('.js-btn-close').forEach(function(button) {
    button.addEventListener('click', function() {
        document.body.style.overflow = 'auto';
        const popupTeam = button.closest('.popup-team');
        if (popupTeam) {
            popupTeam.style.display = 'none';
        }
    });
});