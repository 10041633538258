const SourceTracker = settings => {
	const module = {
		settings,
		init: function() {
			if (typeof Cookies === 'undefined') {
				console.info('Please install js-cookie library to enable the SourceTracker library.');
				return;
			}
			this.setSourceCookies();
		},
		setSourceCookies: function() {
			const params = new URLSearchParams(window.location.search);
			const cookie = {};

			// Save all url params in cookie object
			params.forEach((val, key) => cookie[key] = val);

			// Add any additional values to cookie object
			cookie['referrer'] = document.referrer;
			cookie['landing_pg'] = window.location.href;

			// Save first-touch cookie (only if not previously set)
			if (typeof Cookies.get(this.settings.cookie.name.ft) === 'undefined') {
				Cookies.set(this.settings.cookie.name.ft, JSON.stringify(cookie), this.settings.cookie.opts);
			}

			// In all instances save last-touch cookie
			Cookies.set(this.settings.cookie.name.lt, JSON.stringify(cookie), this.settings.cookie.opts);
		},
		populateFormFields: function() {
			const cookieName = this.settings.cookie.name[this.settings.strategy];
			const vars = Cookies.get(cookieName) ? JSON.parse(Cookies.get(cookieName)) : {};
			const _self = this;

			Object.keys(vars).forEach(key => {
				$(`[${_self.settings.forms.prefillAttr}="${key}"]`).each((i, el) => {
					$(el).val(vars[key]);
				});
			});
		}
	}

	module.init();

	return module;
}

/**
 * Jquery document ready.
 * Initialize SourceTracker library.
 */
$(() => {
	// Init source tracking library
	const st = new SourceTracker({
		strategy: 'ft', // "ft" or "lt" (first-touch or last-touch)
		cookie: {
			name: {
				ft: 'trmk_src_first', // cookie name : first touch attribution
				lt: 'trmk_src_last', // cookie name : last touch attribution
			},
			opts: {
				expires: 1, // 1 day
				path: '/' // full site
			}
		},
		forms: {
			prefillAttr: 'data-prefill', // the data attribute of form field to prefill
		},
	});

	// Gravity forms post render event
	// - Makes sure fields are always popuplated with correct cookie values even if re-rendered (i.e. validation)
	$(document).on('gform_post_render', function (event, form_id, current_page) {
		st.populateFormFields();
	});

	// Fallback for if gform_post_render doesn't fire
	// - gform_post_render is supposed to fire on page load, but seems to not in all cases
	st.populateFormFields();
})
